@import "../../variables";

.HeaderDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Login {
  background-image: url("../../images/bk_login.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .MainContainer {
    row-gap: unset;
  }
  .div2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 0 0 1rem 1rem;
  }

  .loginForm {
    padding: 15px;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    row-gap: 25px;
    width: 90%;
    max-width: 650px;
  }
}

.DBLoginPage {
  .div2 {
    background-color: white;
    border-radius: 0 0 1rem 1rem;
  }
  p {
    text-align: center;
  }
  .loginForm {
    margin: auto;
    padding: 15px;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    row-gap: 25px;
    width: 90%;
    max-width: 650px;
  }
}

.DisplaysPage {
  background-image: url("../../images/bk_db.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .div2 {
    position: relative;
  }

  .newForm {
    background-color: white;
    padding: 15px;
    width: 80%;
    max-width: 750px;
    margin: auto;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    row-gap: 25px;

    .selectOrgDiv {
      display: flex;
      align-items: baseline;

      .select {
        flex: 1;
      }
    }
  }
}
.OneCard {
  .div2 {
    background-color: white !important;
    border-radius: 1rem;

    p {
      padding: 0 15px;
    }
  }
  .images {
    display: flex;
    column-gap: 15px;
    row-gap: 15px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    padding: 25px 0;

    img {
      flex: 1 0 21%; /* explanation below */
    }

    .selectedImg {
      border: 5px solid $gold;
    }
  }
  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 400px;
    border-radius: 1rem;
  }

  video {
    object-fit: contain;
    max-width: 100%;
    max-height: 400px;
    border-radius: 1rem;
  }

  .imgDiv {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    border: 2px solid black;
    padding: 5px;
    border-radius: 1rem;
  }

  .formGrid {
    margin: 25px 0px;

    .MuiGrid-item {
      padding: 25px;
    }

    textarea {
      width: 100%;
    }
  }

  .addImgBtnDiv {
    margin: 25px 0;
  }
}

.UploadToStore {
  .images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  .fileCard {
    // max-width: 400px;
    max-height: 100%;

    img {
      max-width: 100%;
      border-radius: 1rem;
      max-height: 100%;
      min-height: 150px;
    }

    video {
      max-width: 100%;
      border-radius: 1rem;
      max-height: 100%;
    }

    .content {
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      max-height: 200px;
    }

    .footer {
      justify-content: space-between;
      padding: 5px 15px;
      flex-direction: column;

      div {
        display: flex;
        justify-content: space-between;
      }
      p {
        margin: 0;
        font-size: 12px;
      }
    }
  }
}

.Notifications {
  .notificationsDiv {
    display: flex;
    flex-wrap: wrap;

    .notifDiv {
      padding: 2px;
      border-radius: 1rem;
      max-width: 600px;
    }

    .notifDiv:hover {
      background-color: black;
    }
  }
}

.OneDisplay {
  background-image: url("../../images/tausta.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .div2 {
    background-color: white;
  }

  .layoutsDiv {
    background-color: $boxDivBack;
    padding: 15px 5px;
  }

  .layouts {
    display: flex;
    // justify-content: center;

    column-gap: 25px;
    row-gap: 25px;
    overflow-x: auto;
    padding: 15px 0;
    //flex-wrap: wrap;

    max-height: 700px;
  }

  .colorExpDiv {
    display: flex;
    padding: 15px;
    column-gap: 15px;
    flex-wrap: wrap;
    row-gap: 5px;

    div {
      padding: 5px 15px;
      border-radius: 1rem;
    }
  }

  .buttonsDiv {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    row-gap: 15px;
  }

  .tableDiv {
    overflow-x: auto;
  }
  .tableCell {
    background-color: $grey2;
  }
  .tableCell-expired {
    background-color: pink;
  }
  .tableCell-today {
    background-color: $lightGreen;
  }
  .tableCell-active {
    background-color: $gold;
  }
  .scheduledDiv {
    padding: 15px 5px;
  }

  .tableIconBtns {
    display: flex;

  }

  .basicInfoDiv {
    .buttonsDiv {
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-start;

      div {
        flex: 1;
        text-align: center;
      }
    }
    .innerBasicDiv {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
      background-color: white;
      width: 90%;
      max-width: 750px;
      border: 2px solid $onlyWinfo;
      border-radius: 1rem;
      margin: auto;
      padding: 15px;

      h2 {
        text-align: center;
      }
    }
  }

  .statusBox {
    padding: 15px 0;
    flex: 1;
    display: flex;
    align-items: center;
    column-gap: 5px;

    p {
      margin: 0;
    }
  }

  .dot {
    height: 20px;
    width: 20px;
    border-radius: 1rem;
  }

  .dot-online {
    @extend .dot;
    background-color: green;
  }

  .dot-offline {
    @extend .dot;

    background-color: red;
  }

  .settingsDiv {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
      transform: none;
    }

    .Mui-expanded {
      background-color: $grey2;
    }

    .Mui-checked {
      color: green;
    }

    .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
      background-color: $lightGreen;
    }

    .box1 {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
    }
  }
}

.Layouts {
  .layoutsDiv {
    display: flex;
    row-gap: 15px;
    column-gap: 15px;
    flex-wrap: wrap;
  }
}

.LayoutSelectCards {
  padding: 10px;

  /*.steps {
    overflow-x: auto;

  }*/

  .cards {
    margin-bottom: 50px;
    background-color: black;
  }

  .emptyCard {
    border: 2px solid black;
  }
}

.HomePage {
  background-image: url("../../images/tausta.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  p {
    margin: 0;
  }

  h1 {
    margin: 0;
    color: white;
  }

  .div1 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex: 1;

    .div1-right {
      p {
        color: white;
        font-size: 22px;
        padding: 5px 0;
      }
    }
  }
  .div2 {
    padding: 0 15px;
    flex: 2;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

.LayoutsPage {
  background-image: url("../../images/bk_layouts.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .newForm {
    background-color: white;
    width: 80%;
    max-width: 650px;
    margin: auto;
    padding: 10px;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 15px;
  }
}

@media screen and (max-width: 920px) {
  .UploadToStore {
    .images {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 650px) {
  .Layouts {
    .layoutsDiv {
      flex-direction: column;
    }
  }

  .HomePage {
    h1 {
      font-size: 16px;
    }
    .mainContainer {
      top: 5px;
      bottom: 5px;
      left: 5px;
      right: 5px;
    }
    .div1 {
      flex: unset;
      flex-direction: column-reverse;
      padding: 0 15px;
      margin-bottom: 15px;

      .div1-right {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
      }
    }

    .div2 {
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      overflow: auto;
    }
  }

  .UploadToStore {
    .fileCard {
      max-width: unset;
      height: 300px;
    }
    .images {
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 10px;
    }
  }

  .OneDisplay {
    .layouts {
      flex-wrap: nowrap;
      justify-content: unset;
    }
  }

  .DisplaysPage {
    .div1 {
      flex-direction: column-reverse;
      justify-content: flex-start;
      flex: 0 !important;

      .div1-right {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
      }
    }
  }
}

.RegisterDb {
  display: flex;
  flex-direction: column;

  .header {
    flex: 1;
    text-align: center;
  }

  .loginForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 25px;

    padding: 50px 15px;
    margin: 10px;
    border-radius: 1rem;
  }

  .footer {
    flex: 1;
  }
}
