@import "../../variables";

.RootRoot {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;

  .qrCodeBox {
    background-color: $grey2;
    padding: 50px;
  }
}

.RootDiv {
  transform: rotate(var(--rotation));
  position: relative;
}

.invisibleDiv {
  height: 100px;
  width: 100px;
  z-index: 99999;
}

.invisibleDiv-left {
  @extend .invisibleDiv;
  position: absolute;
  top: 0;
  left: 0;
}

.invisibleDiv-right {
  @extend .invisibleDiv;
  position: absolute;
  bottom: 0;
  right: 0;
}

.AskPinRoot {
  position: absolute;
  bottom: 0px;
  right: 0px;
  top: 0px;
  left: 0px;
  z-index: 9999999999999;
  background-color: rgba(0, 0, 0, 0.7);

  display: flex;
  justify-content: center;
  align-items: center;

  .containerDiv {
    background-color: white;
    padding: 25px 15px;
    width: 90%;
    max-width: 850px;
    border-radius: 1rem;
    height: calc(100% - 75px);
    overflow: auto;
  }

  .pinCodeDiv {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    background-color: $grey2;
    padding: 10px 25px;

    .buttons {
      display: flex;
      column-gap: 25px;
    }
  }

  .contentDiv {
    iframe {
      margin: 15px 0;
      height: 400px;
      width: 100%;
    }

    .acDiv1 {
      .networkDiv {
        p {
          margin: 0;
        }
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        .online {
          color: green;
        }
        .offline {
          color: red;
        }
      }
    }

    .acDiv3 {
      .allTimesDiv {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 25px;

        div {
          flex: 1;
          min-width: 35%;
          background-color: $grey2;
          padding: 15px;
          border-radius: 1rem;
        }
      }
    }

    .acDiv4 {
      h4 {
        position: sticky;
        top: 0;
        background-color: $grey2;
        padding: 15px;
      }
      .itemDiv {
        max-height: 500px;
        overflow: auto;
      }
    }
  }
  /*
  .askPinDiv {
    padding: 25px 15px;
    border-radius: 1rem;
    width: 90%;
    max-width: 750px;
    background-color: white;
    overflow: auto;
    height: calc(100% - 75px);

    .div1 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .text {
      width: 100%;
      flex: 2;

      padding-bottom: 25px;
    }

    .buttons {
      width: 100%;
      flex: 1;

      display: flex;
      column-gap: 25px;

      button {
        flex: 1;
      }
    }
  }*/
}

.DB {
  background-color: black;
  padding: var(--padding);

  .cards {
    height: inherit;
  }

  .type-full {
    display: flex;

    .DiaCard {
      flex: 1;
      position: relative;

      .direction-span {
        // background-color: $grey;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100px;

        display: flex;
        justify-content: center;
        align-items: center;

        .iconBtn {
          .MuiSvgIcon-root {
            height: 50px;
            width: 50px;
            color: $grey;
          }
        }
      }

      .right-direction {
        @extend .direction-span;
        right: 0;
      }

      .left-direction {
        @extend .direction-span;
        left: 0;
      }
    }
    .VideoCard {
      flex: 1;
    }

    .ClockCard {
      flex: 1;
    }

    .MediaCard {
      flex: 1;
    }
  }

  .type-side-by-side {
    display: flex;
    column-gap: var(--colGap);

    .DiaCard {
      flex: 1;
    }

    .VideoCard {
      flex: 1;
    }

    .ClockCard {
      flex: 1;
    }

    .MediaCard {
      flex: 1;
    }
  }

  .type-up-down {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowGap);

    .DiaCard {
      flex: 1;
    }

    .VideoCard {
      flex: 1;
    }

    .ClockCard {
      flex: 1;
    }

    .MediaCard {
      flex: 1;
    }
  }

  .type-2x2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: var(--rowGap);
    column-gap: var(--colGap);
  }
}

.DiaCard {
  // background-color: white;
  div {
    height: 100%;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.MediaCard {
  display: flex;
  position: relative;

  p {
    color: white;
  }

  .imgDiv {
    flex: 1;
    z-index: 2;

    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .imgDiv2 {
    flex: 1;
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .fadeOut {
    animation: fadeOutAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
  }

  .fadeIn {
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
  }
  .videoDiv {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    justify-content: center;
  }

  .videoDiv2 {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;

    display: flex;
    justify-content: center;
  }

  video {
    width: 100%;
    max-height: 100%;
  }

  .direction-span {
    // background-color: $grey;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 3;

    .iconBtn {
      .MuiSvgIcon-root {
        height: 50px;
        width: 50px;
        color: $grey;
      }
    }
  }

  .right-direction {
    @extend .direction-span;
    right: 0;
  }

  .left-direction {
    @extend .direction-span;
    left: 0;
  }
}

.VideoCard {
  position: relative;

  div {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    justify-content: center;
  }

  video {
    width: 100%;
    max-height: 100%;
  }
}

.ClockCard {
  color: $gold;

  .content {
    height: 100%;
    background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.BottomBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
  }
  // background-color: var(--backgroundColor);
  // color: var(--textColor);
}

.TopBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    color: yellow;
  }
  //background-color: var(--backgroundColor);
  // color: var(--textColor);
}

.usePreviewDiv {
  background-color: green;
  z-index: 9999999999;
  position: fixed;
  top: 50px;
  right: 50px;
}
