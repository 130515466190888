@import "../../variables";

.RootRoot {
  //background-color: green;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  .invisibleDiv {
    height: 100px;
    width: 100px;
    z-index: 99999;
  }

  .invisibleDiv-left {
    @extend .invisibleDiv;
    position: absolute;
    top: 0;
    left: 0;
  }

  .invisibleDiv-right {
    @extend .invisibleDiv;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .invisibleDiv-topRight {
    @extend .invisibleDiv;
    position: absolute;
    top: 0;
    right: 0;
  }


  .invisibleDiv-topRight:active {
    background-color: rgba(43, 42, 42, 0.499);
  }
}

.DBrootDiv {
  //padding: var(--padding);
  z-index: 99;
  transform: rotate(var(--rotation));
  //position: relative;
  //height: 100vh;

  p {
    margin: 0;
  }

  .contentDiv {
    // background-color: yellow;
    /* position: absolute;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;*/
  }


  .centerDiv {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .qrCodeDiv {
    width: 100%;
    min-height: 100vh;
    background-color: black;
  }

  .qrCodeBox {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    background-color: $gold;
    padding: 15px;
    border-radius: 1rem;
   
  }
  .usePreviewDiv {
    z-index: 9999999999;
    position: fixed;
    top: 50px;
    right: 50px;
  }




  .emptyDiv {
    height: 100%;
    background-color: black;
  }
}

.DBContent {
  height: inherit;
  //background-color: yellow;

  .cardsDiv {
    height: 100%;
  }

  // FULL
  .type-full {
    display: flex;

    .MediaCard {
      flex: 1;
    }

    .DiaCard {
      flex: 1;
      position: relative;

      .direction-span {
        // background-color: $grey;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100px;

        display: flex;
        justify-content: center;
        align-items: center;

        .iconBtn {
          .MuiSvgIcon-root {
            height: 50px;
            width: 50px;
            color: $grey;
          }
        }
      }

      .right-direction {
        @extend .direction-span;
        right: 0;
      }

      .left-direction {
        @extend .direction-span;
        left: 0;
      }
    }
  }

  // SIDE BY SIDE
  .type-side-by-side {
    display: flex;
    column-gap: var(--colGap);

    .MediaCard {
      flex: 1;
    }

    .DiaCard {
      flex: 1;
    }
  }

  // UP DOWN
  .type-up-down {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowGap);

    .MediaCard {
      flex: 1;
    }

    .DiaCard {
      flex: 1;
    }
  }

  // 2x2
  .type-2x2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: var(--rowGap);
    column-gap: var(--colGap);
  }
}

.DiaCard {
  // background-color: white;
  div {
    height: 100%;
  }
}

.MediaCard {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  p {
    color: white;
  }

  .imgDiv {
    flex: 1;
    z-index: 2;

    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .imgDiv2 {
    flex: 1;
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    //object-fit: contain;
  }
  .fadeOut {
    animation: fadeOutAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
  }

  .fadeIn {
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
  }
  .videoDiv {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    justify-content: center;
  }

  .videoDiv2 {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;

    display: flex;
    justify-content: center;
  }

  video {
    width: 100%;
    max-height: 100%;
  }

  .direction-span {
    // background-color: $grey;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 3;

    .iconBtn {
      .MuiSvgIcon-root {
        height: 50px;
        width: 50px;
        color: $grey;
      }
    }
  }

  .right-direction {
    @extend .direction-span;
    right: 0;
  }

  .left-direction {
    @extend .direction-span;
    left: 0;
  }
}
